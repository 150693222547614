<template>
  <div class="main-box">
    <div class="min-box-header">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>考试训练</el-breadcrumb-item>
        <el-breadcrumb-item>答题详情</el-breadcrumb-item>
        <el-breadcrumb-item>查看详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="primary" @click="goBack">返回</el-button>
    </div>
    <el-scrollbar class="main-wrapper">
      <StudentAnswerDetail v-if="Object.keys(answerData).length > 0" :answerData="answerData" />
      <div v-else style="text-align: center;margin-top: 40px">暂无数据</div>
    </el-scrollbar>
  </div>
</template>

<script>
import {trainAnswerDetailTeacher} from '@/utils/apis'
import StudentAnswerDetail from '@/components/StudentAnswerDetail.vue'
export default {
  name: "ExamStudentAnswerDetail",
  components:{
    StudentAnswerDetail
  },
  data(){
    return {
      userId:this.$route.query.userId || null,
      trainLogId:this.$route.query.trainLogId || null,
      answerData:{}
    }
  },
  mounted() {
    this.getTrainAnswerDetailTeacher()
  },
  methods:{
    getTrainAnswerDetailTeacher(){
      let params = {
        student_theory_train_log_id:this.trainLogId,
        user_id:this.userId
      }
      trainAnswerDetailTeacher(params).then((res)=>{
        for(let i in res.data.bottom_data){
          res.data.bottom_data[i].list.forEach(item=>{
            this.$set(item, 'isAnalysisShow', false)
          })
        }
        this.answerData = res.data;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.min-box-header{
  display:flex;
  align-items: center;
  justify-content:space-between;
  margin:0 20px;
  padding-bottom: 14px;
  border-bottom: 2px solid #F1F5FF;
  ::v-deep .el-breadcrumb{
    .el-breadcrumb__item{
      .el-breadcrumb__inner{
        color: #999999;
      }
      &:last-of-type{
        .el-breadcrumb__inner{
          color: #333333;
        }
      }
    }
  }
}
.main-box {
  height: 100%;
}

.main-wrapper {
  height: 100%;

  ::v-deep > .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>